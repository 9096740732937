import classnames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";

import { getAttachmentDownloadUrl } from "@web/attachments";
import { getBadgeSettingsFromOrderType } from "@web/common";
import {
  Badge,
  DigitalAssetsWrapper,
  Heading,
  Label,
  SummaryBoxUI,
  TabItem,
  Tabs,
  containerPadding,
} from "@web/ui";
import { LEGACY_formatDate, formatMoney } from "@web/utils";

import {
  SupplierOrder,
  SupplierOrderChanges,
  SupplierOrderService,
  SupplierUiOrder,
  SupplierUiOrderItem,
} from "src/domain";

import { ActivityBox, AssignToPO, OrderChanges, OrderComments, OrderItems, Topbar } from "../";

interface Props {
  order: SupplierOrder;
  orderChanges: SupplierOrderChanges;
  orderUiItems: SupplierUiOrderItem[];
  invalidateOrderQueries: () => void;
}

export const OrderDetailsComponent: React.FC<Props> = ({
  order,
  orderChanges,
  orderUiItems,
  invalidateOrderQueries,
}) => {
  const { version, ...uiOrder }: SupplierOrder & SupplierUiOrder = order;

  const [orderVersion, setOrderVersion] = useState<number>(version);

  const hasOrderChanges: boolean = useMemo(
    () => SupplierOrderService.hasOrderChanges(orderChanges),
    [orderChanges]
  );

  const canShowOrderType = !SupplierOrderService.isDefaultOrderType(order);

  useEffect(() => {
    setOrderVersion(version);
  }, [version]);

  const hasComments: boolean = SupplierOrderService.hasOrderComments(order);
  const isOrderIncoming = SupplierOrderService.isOrderIncoming(order);
  const hasAttachments = SupplierOrderService.hasOrderAttachments(order);

  const showAttachments = hasAttachments;
  const showPriceBreakdown = !isOrderIncoming;

  const handleAssigneeChange = useCallback((newOrderVersion: number) => {
    setOrderVersion(newOrderVersion);
  }, []);

  const tabItems: TabItem[] = useMemo(
    () => [
      {
        id: "FINAL_ITEMS",
        label: "Final Items",
        "data-testid": "finalItemsTabButton",
        children: <OrderItems orderItems={orderUiItems} className="pt-2" />,
      },
      {
        id: "CHANGES",
        label: "Changes",
        "data-testid": "changesTabButton",
        children: <OrderChanges orderChanges={orderChanges} />,
      },
    ],
    [orderChanges, orderUiItems]
  );

  return (
    <>
      <div className="flex flex-col min-h-full bg-neutral_100">
        <Topbar
          order={order}
          orderVersion={orderVersion}
          invalidateOrderQueries={invalidateOrderQueries}
          className="flex-none"
        />
        <div className="flex-1">
          <div className={`py-5 ${containerPadding} flex justify-center container`}>
            <div className="w-4/6 mr-7">
              {showAttachments && (
                <>
                  <div
                    className="flex flex-row w-full items-start justify-between"
                    data-testid="orderDetails_orderAttachmentsHeader"
                  >
                    <Heading size="300">Attachments ({uiOrder.attachments.length})</Heading>
                  </div>
                  <div className="mt-2 mb-6 flex justify-between flex-wrap gap-4">
                    <DigitalAssetsWrapper
                      assets={uiOrder.attachments.map((asset) => ({
                        ...asset,
                        key: asset.attachmentId,
                        createdDate: LEGACY_formatDate(asset.createdDate),
                        nameUploadedBy: asset.createdBy,
                        downloadLink: getAttachmentDownloadUrl(asset.attachmentId),
                      }))}
                      linkedPageLabel="View All Attachments"
                      linkedPageUrl={`/order/${uiOrder.orderId}/attachments`}
                      showLimit={2}
                    />
                  </div>
                </>
              )}
              <div
                className="flex flex-row w-full items-center justify-between"
                data-testid="orderDetails_orderItemsHeader"
              >
                <Heading size="300" className="mr-2">
                  Ordered Items ({orderUiItems.length})
                </Heading>
                {canShowOrderType && (
                  <Badge
                    {...getBadgeSettingsFromOrderType({
                      orderType: order.orderType,
                      text: order.subject,
                    })}
                    size="s"
                    className="mr-auto"
                  />
                )}

                {isOrderIncoming && (
                  <div className="flex flex-row items-center">
                    <Label size="200">Total</Label>
                    <Label size="100" className="pl-2">
                      {formatMoney(uiOrder.totalGrossAmount)}
                    </Label>
                  </div>
                )}
              </div>
              {hasOrderChanges ? (
                <Tabs
                  className="mt-4"
                  size="large"
                  variant="line"
                  items={tabItems}
                  initialTabId="FINAL_ITEMS"
                  data-testid="orderDetails_tabs"
                />
              ) : (
                <OrderItems orderItems={orderUiItems} />
              )}
            </div>
            <div className="w-1/3 h-min">
              <AssignToPO
                order={order}
                orderVersion={orderVersion}
                disabled={!isOrderIncoming}
                onAssigneeChange={handleAssigneeChange}
              />

              {showPriceBreakdown && (
                <SummaryBoxUI
                  title="Price breakdown"
                  nrLineItems={uiOrder.items.length}
                  vatPrice={SupplierOrderService.formatZeroAmountInOrderCurrency(order)}
                  additionalCosts={SupplierOrderService.convertOrderPriceModifiersToUiModel(
                    uiOrder.amountAdditionalCosts
                  )}
                  discounts={SupplierOrderService.convertOrderPriceModifiersToUiModel(
                    uiOrder.amountDiscounts
                  )}
                  totalItemsPrice={SupplierOrderService.formatOrderItemsTotalAmount(
                    uiOrder.items,
                    uiOrder.totalGrossAmount.currencyCode
                  )}
                  totalPrice={formatMoney(uiOrder.totalGrossAmount)}
                />
              )}
              <ActivityBox
                className={classnames({
                  "mt-4": showPriceBreakdown,
                })}
                order={uiOrder}
                testId="orderDetails_orderInformation"
              />
              {hasComments && <OrderComments comments={uiOrder.comments} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
