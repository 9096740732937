import { PlusIcon } from "@heroicons/react/solid";
import { ErrorMessage } from "@hookform/error-message";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import _compact from "lodash/compact";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";

import { NavigationPrompt } from "@web/common";
import {
  Heading,
  Label,
  Modal,
  OrderConfiguratorHeader,
  OrderConfiguratorSummary,
  RegularButton,
  containerPadding,
} from "@web/ui";
import { isDefined } from "@web/utils";

import { RoutesConfig, RoutesParams } from "src/config/routes";
import { useSystemMessages } from "src/context/SystemMessages";
import {
  AddItemModal,
  Attachment,
  AttachmentsModalController,
  Change,
  ChangeTypesMap,
  ChangesSyncService,
  DraftSupplierOrderForm,
  IOrderItemQuantityChangeMeta,
  ItemActionSuccessModal,
  OrderEditDiscardChangesConfirmationModal,
  OrderItemAddition,
  OrderItemAdditionValue,
  OrderItemReplacement,
  OrderItemReplacementValue,
  PriceModifierRemovalValue,
  SupplierOrderAttachment,
  SupplierOrderItemForm,
  SupplierOrderService,
  ValidatedSupplierOrderForm,
  ValidatedSupplierOrderSchema,
  isApiError,
  useLinkAttachmentsToOrderMutation,
  useOrderAttachmentsApiError,
  useOrderEditsSync,
} from "src/domain";
import { ApiError, AttachmentInformation, AttachmentInformationChangeResponse } from "src/typegens";

import { OrderEditPriceModifiers } from "../OrderEditPriceModifiers";
import { OrderItemEditController } from "../OrderItemEditController";
import { OrderReplacedItemController } from "../OrderReplacedItemController";

interface Props {
  originalOrderFormData: DraftSupplierOrderForm;
  editedOrderFormData: DraftSupplierOrderForm;
}

export const OrderEditForm: React.FC<Props> = ({ originalOrderFormData, editedOrderFormData }) => {
  const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successModalMessage, setSuccessModalMessage] = useState("");
  const [areAttachmentsDirty, setAreAttachmentsDirty] = useState(false);
  const [arePriceModifiersDirty, setArePriceModifiersDirty] = useState(false);
  const [isNavigationConfirmationDisabled, setIsNavigationConfirmationDisabled] = useState(false);
  const navigate = useNavigate();
  const { setSystemMessage } = useSystemMessages();
  const { syncedEdits, didSyncedEditsChange } = useOrderEditsSync();
  const { addEdit, editsToRollback, didEditsToRollbackChange, isSyncInProgress } =
    useOrderEditsSync();

  const { orderId } = originalOrderFormData;
  const currencyCode = editedOrderFormData.totalGrossAmount.currencyCode;

  const form = useForm<ValidatedSupplierOrderForm>({
    defaultValues: editedOrderFormData,
    resolver: zodResolver(ValidatedSupplierOrderSchema),
  });

  const { control, watch, setValue, getValues, handleSubmit, formState, reset: resetForm } = form;
  const { isDirty: isOrderDirty, errors } = formState;

  const {
    fields: formOrderItems,
    update: updateFormItem,
    append: appendFormItem,
    remove: removeFormItem,
  } = useFieldArray({
    control,
    name: "items",
    // Set custom key where react-hook-form generates its internal ID for each collection element
    // See also: https://spectrum.chat/react-hook-form/help/useformarray-is-overriding-the-id-on-my-data~a976690e-dfdd-4736-81c7-1a740b0b3fc7
    keyName: "key",
  });

  const { fields: formOrderAttachments, replace: replaceFormAttachments } = useFieldArray({
    control,
    name: "attachments",
    // Set custom key where react-hook-form generates its internal ID for each collection element
    // See also: https://spectrum.chat/react-hook-form/help/useformarray-is-overriding-the-id-on-my-data~a976690e-dfdd-4736-81c7-1a740b0b3fc7
    keyName: "key",
  });

  const {
    fields: formAmountAdditionalCosts,
    append: appendFormAmountAdditionalCost,
    remove: removeFormAmountAdditionalCost,
  } = useFieldArray({
    control,
    name: "amountAdditionalCosts",
    // Set custom key where react-hook-form generates its internal ID for each collection element
    // See also: https://spectrum.chat/react-hook-form/help/useformarray-is-overriding-the-id-on-my-data~a976690e-dfdd-4736-81c7-1a740b0b3fc7
    keyName: "key",
  });

  const {
    fields: formAmountDiscounts,
    append: appendFormAmountDiscount,
    remove: removeFormAmountDiscount,
  } = useFieldArray({
    control,
    name: "amountDiscounts",
    // Set custom key where react-hook-form generates its internal ID for each collection element
    // See also: https://spectrum.chat/react-hook-form/help/useformarray-is-overriding-the-id-on-my-data~a976690e-dfdd-4736-81c7-1a740b0b3fc7
    keyName: "key",
  });

  const currentGrandTotal = watch("totalGrossAmount");
  const numberOfItems = getValues("items").filter((item) => item.quantity > 0).length;
  const orderVersionId = watch("version");

  const { showOrderAttachmentsApiError } = useOrderAttachmentsApiError(formOrderAttachments);

  const attachments: Attachment[] = useMemo(
    () =>
      formOrderAttachments.map((orderAttachment) =>
        SupplierOrderService.convertFromOrderAttachmentToValidatedAttachment(orderAttachment)
      ),
    [formOrderAttachments]
  );

  const showWarning = useCallback(
    (warningMessage: string) => {
      setSystemMessage({
        type: "warning",
        message: warningMessage,
      });
    },
    [setSystemMessage]
  );

  const showGenericSavingError = () => {
    setSystemMessage({
      type: "failure",
      message: "There was an error while saving your changes. Please try again.",
    });
  };

  const replaceItem = useCallback(
    (itemToReplaceIndex: number, replacement: SupplierOrderItemForm) => {
      const orderItems = getValues("items");
      const orderItemToReplace = orderItems[itemToReplaceIndex];
      const isReplacementOrderItemAlreadyInForm = !!orderItems.find(
        (item) => item.id === replacement.id
      );
      const isRestoreAction = !SupplierOrderService.isOrderItemReplacement(replacement);

      if (isReplacementOrderItemAlreadyInForm) {
        if (isRestoreAction) {
          showWarning(
            `The item you are trying to restore has been already manually added to the order. Please update it manually. Line item ${replacement.lineNumber} has been removed.`
          );

          // Push new quantity value to be synced when it changes
          addEdit({
            type: "orderItemQuantityChange",
            meta: {
              orderId,
              oldOrderVersionId: orderVersionId,
              orderItemId: orderItemToReplace.id,
            },
            oldValue: orderItemToReplace.quantity,
            newValue: 0,
          });
          setValue(`items.${itemToReplaceIndex}.quantity`, 0);
          return;
        }

        showWarning(
          `The item you are trying to replace line item ${replacement.lineNumber} with, already exists in the order. Please update it manually.`
        );
        return;
      }

      const originalItemId = isRestoreAction ? replacement.id : replacement.replacementForItemId;

      if (!originalItemId) {
        throw new Error("Unknown original item ID");
      }

      addEdit({
        type: "orderItemReplacement",
        meta: {
          orderId,
          oldOrderVersionId: orderVersionId,
          orderItemId: originalItemId,
        },
        oldValue: {
          ...orderItemToReplace,
        },
        newValue: {
          ...replacement,
        },
      });
      updateFormItem(itemToReplaceIndex, replacement);

      if (isRestoreAction) {
        openSuccessModal(`Line item ${replacement.lineNumber} has been restored to the original`);
        return;
      }
      openSuccessModal(`Line item ${replacement.lineNumber} has been replaced`);
    },
    [getValues, updateFormItem, setValue, showWarning, addEdit, orderId, orderVersionId]
  );

  const addItem = useCallback(
    (newItem: SupplierOrderItemForm) => {
      appendFormItem({
        ...newItem,
      });
      closeAddItemModal();
      openSuccessModal(`Item "${newItem.name}" has been added`);

      // Add edit to sync
      addEdit({
        type: "orderItemAddition",
        meta: {
          orderId,
          orderItemId: newItem.id,
          oldOrderVersionId: orderVersionId,
        },
        oldValue: undefined,
        newValue: {
          id: newItem.id,
          name: newItem.name,
          quantity: newItem.quantity,
        },
      });
    },
    [appendFormItem, addEdit, orderId, orderVersionId]
  );

  // Handle removal of those items, which errored during addition sync
  useEffect(() => {
    if (!didEditsToRollbackChange) {
      return;
    }

    // Remove all items whose syncs failed
    const orderItemsToRemove = editsToRollback.filter(
      (edit) => edit.type === "orderItemAddition"
    ) as OrderItemAddition[];

    const orderItems = getValues("items");
    orderItemsToRemove.forEach((edit) => {
      const indexOfFormItemToRemove = orderItems.findIndex(
        (orderItem) => orderItem.id === edit.newValue.id
      );
      if (isDefined(indexOfFormItemToRemove)) {
        removeFormItem(indexOfFormItemToRemove);
      }
    });
  }, [didEditsToRollbackChange, editsToRollback, getValues, removeFormItem]);

  // Handle rollback of those items, which errored during item replacement sync
  useEffect(() => {
    if (!didEditsToRollbackChange) {
      return;
    }

    // Remove all items whose syncs failed
    const replacementsToRollback = editsToRollback.filter(
      (edit) => edit.type === "orderItemReplacement"
    ) as OrderItemReplacement[];

    const orderItems = getValues("items");
    replacementsToRollback.forEach((edit) => {
      const indexOfFormItemToRollback = orderItems.findIndex(
        (orderItem) => orderItem.id === edit.newValue.id
      );
      if (isDefined(indexOfFormItemToRollback)) {
        updateFormItem(indexOfFormItemToRollback, edit.oldValue);
      }
    });
  }, [didEditsToRollbackChange, editsToRollback, getValues, removeFormItem, updateFormItem]);

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      const itemOrderTotalAmountFieldNamePattern = /items\.\d+\.totalAmount\.amount/;
      const amountAdditionalCostsFieldNamePattern = /amountAdditionalCosts/;
      const amountDiscountsFieldNamePattern = /amountDiscounts/;

      if (
        itemOrderTotalAmountFieldNamePattern.test(name as string) ||
        amountAdditionalCostsFieldNamePattern.test(name as string) ||
        amountDiscountsFieldNamePattern.test(name as string)
      ) {
        const orderItemsTotalAmount = SupplierOrderService.getOrderItemsTotalAmount(
          getValues("items")
        );
        const amountAdditionalCostsTotalAmount =
          SupplierOrderService.getOrderAmountPriceModifiersAmount(
            getValues("amountAdditionalCosts")
          );
        const amountDiscountsTotalAmount = SupplierOrderService.getOrderAmountPriceModifiersAmount(
          getValues("amountDiscounts")
        );

        const currentGrandTotalAmount = getValues("totalGrossAmount.amount");
        const newGrandTotalAmount =
          orderItemsTotalAmount + amountAdditionalCostsTotalAmount + amountDiscountsTotalAmount;

        const areCurrentAndNewGrandTotalsEqual = SupplierOrderService.areAmountsEqual(
          currentGrandTotalAmount,
          newGrandTotalAmount
        );

        if (!areCurrentAndNewGrandTotalsEqual) {
          setValue("totalGrossAmount.amount", newGrandTotalAmount);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, getValues, setValue]);

  //-----------------------------------------------------------------

  const setNewOrderVersion = useCallback(
    (newOrderVersionId: number) => {
      setValue("version", newOrderVersionId);

      // We are doing some dirty hacking here. We need to update the order edit
      // page path, but it would result in a navigation confirmation dialog
      // about losing unsaved changes. To avoid this, we disable the navigation
      // confirmation dialog for a short time. The timeout is here to allow
      // the state of the component to update before the navigation happens.
      // Someone could think about resetting the form with the "saved" data,
      // however the requirement is that cancelling the edit page and going
      // back to order details should trigger the navigation confirmation prompt,
      // as there is no way to get back to order details page with the "saved"
      // data in the UI of the order details page - the edits are discarded
      // upon order details page entry.
      setIsNavigationConfirmationDisabled(true);
      setTimeout(() => {
        // Update order edit page path to match new order version
        navigate(
          generatePath(RoutesConfig.orderEditVersion, {
            [RoutesParams.ORDER_ID]: orderId,
            [RoutesParams.ORDER_VERSION_ID]: newOrderVersionId.toString(),
          }),
          { replace: true }
        );
        setTimeout(() => {
          setIsNavigationConfirmationDisabled(false);
        }, 50);
      }, 50);
    },
    [navigate, orderId, setValue]
  );

  // Set new order version whenever changes sync queue updates with new syncs
  useEffect(() => {
    const latestSyncedChange = syncedEdits.at(syncedEdits.length - 1);

    if (
      !didSyncedEditsChange ||
      !isDefined(latestSyncedChange) ||
      !ChangesSyncService.isSyncedChange(latestSyncedChange)
    ) {
      return;
    }

    const newOrderVersionId = latestSyncedChange.meta.newOrderVersionId;

    setNewOrderVersion(newOrderVersionId);
  }, [didSyncedEditsChange, syncedEdits, setValue, setNewOrderVersion]);

  const rolledBackEditInfoGetters = useMemo(
    (): ChangeTypesMap<(change: Change) => string> => ({
      orderItemQuantityChange: (edit: Change): string => {
        const { orderItemId } = edit.meta as IOrderItemQuantityChangeMeta;
        const orderItem = getValues("items").find((item) => item.id === orderItemId);

        if (!orderItem) {
          return "";
        }

        const orderItemName = orderItem.name;
        const lineItemNumber = orderItem.lineNumber;
        const rollbackValue = edit.oldValue;
        return `${
          isDefined(lineItemNumber) ? `${lineItemNumber}. ` : ""
        }${orderItemName} - quantity rolled back to: ${rollbackValue}`;
      },
      priceModifierRemoval: (edit: Change): string => {
        const { name, changeType } = edit.oldValue as PriceModifierRemovalValue;
        let changeTypeText = "";
        switch (changeType) {
          case "DISCOUNT":
            changeTypeText = "Discount";
            break;
          case "ADDITIONAL":
            changeTypeText = "Additional cost";
            break;
          default:
            changeTypeText = "Price modifier";
            break;
        }
        return `${changeTypeText} "${name}" was restored`;
      },
      orderItemAddition: (edit: Change): string => {
        const { name } = edit.newValue as OrderItemAdditionValue;
        return `Line item "${name}" was removed`;
      },
      orderItemReplacement: (edit: Change): string => {
        const { id: replacementOrOriginalItemId } = edit.newValue as OrderItemReplacementValue;
        const { orderItemId: originalItemId } = edit.meta;

        const originalOrderItem = originalOrderFormData.items.find(
          (item) => item.id === originalItemId
        );
        const isRestoreAction = replacementOrOriginalItemId === originalItemId;

        if (isRestoreAction) {
          return `Restoration of line item ${originalOrderItem?.lineNumber} to original item was rolled back`;
        }

        return `Replacement of line item ${originalOrderItem?.lineNumber} was rolled back`;
      },
    }),
    [getValues, originalOrderFormData.items]
  );

  useEffect(() => {
    if (!didEditsToRollbackChange || editsToRollback.length === 0) {
      return;
    }

    // Reversing edits to rollback will allow to track the changes that are rolled back from the most
    // recent to the oldest, so it will be clearer to the user.
    const reversedEditsToRollback = [...editsToRollback].reverse();
    const rolledBackEditsInfos: string[] = _compact(
      reversedEditsToRollback.map((edit) => {
        const errorInfo = rolledBackEditInfoGetters[edit.type](edit);
        return errorInfo ? `- ${rolledBackEditInfoGetters[edit.type](edit)}\n` : "";
      })
    );

    setSystemMessage({
      type: "failure",
      message:
        "There was an error while saving your changes. The following edits have been rolled back:\n" +
        rolledBackEditsInfos.join("") +
        "\nPlease try again.",
    });
  }, [didEditsToRollbackChange, editsToRollback, rolledBackEditInfoGetters, setSystemMessage]);

  //-----------------------------------------------------------------

  const onOrderConfirm = () => {
    if (isSyncInProgress) {
      return;
    }

    handleSubmit(
      (formData) => {
        resetForm(formData);

        const confirmOrderPagePath = generatePath(RoutesConfig.confirmOrderVersion, {
          [RoutesParams.ORDER_ID]: orderId,
          [RoutesParams.ORDER_VERSION_ID]: orderVersionId.toString(),
        });

        /**
         * Needs to be deferred for until the component re-renders with updated formState
         * that so NavigationPrompt won't activate.
         */
        setTimeout(() => {
          navigate(confirmOrderPagePath);
        }, 50);
      },
      () => {
        // TODO #2158: Check what is logged to sentry in this case and provide some manual logging if needed
        // TODO #2159: Decide on error handling apart of just displaying error message
        setSystemMessage({
          type: "failure",
          message: "The form contains some errors, which prevent the order from being confirmed.",
        });
      }
    )().catch(console.error);
  };

  const onCancel = async () => {
    // Let NavigationPrompt handle both asking for discarding changes, and discarding the changes using beforeDiscardChangesConfirmation
    navigate(generatePath(RoutesConfig.orderDetails, { [RoutesParams.ORDER_ID]: orderId }));
  };

  const openAddItemModal = () => setIsAddItemModalOpen(true);
  const closeAddItemModal = () => setIsAddItemModalOpen(false);

  const openSuccessModal = (message: string) => {
    setSuccessModalMessage(message);
    setIsSuccessModalOpen(true);
  };
  const closeSuccessModal = () => {
    setSuccessModalMessage("");
    setIsSuccessModalOpen(false);
  };

  const linkAttachmentsMutation = useLinkAttachmentsToOrderMutation({
    onSuccess: (apiResponse: AttachmentInformationChangeResponse) => {
      const apiAttachments: AttachmentInformation[] = apiResponse.attachmentInformation;
      const orderAttachments: SupplierOrderAttachment[] = apiAttachments.map((apiAttachment) =>
        SupplierOrderService.convertFromApiAttachmentToOrderAttachment(apiAttachment)
      );

      replaceFormAttachments(orderAttachments);
      setNewOrderVersion(apiResponse.newVersion);

      setSystemMessage({
        type: "success",
        message: "Your changes have been saved.",
      });
    },
    // TODO #2158: Check what is logged to sentry in this case and provide some manual logging if needed
    onError: (error: ApiError | unknown) => {
      if (isApiError(error)) {
        showOrderAttachmentsApiError(error);
        return;
      }

      showGenericSavingError();
    },
  });

  const submitAttachmentsChanges = (editedValidatedAttachments: Attachment[]) => {
    const editedAttachments = editedValidatedAttachments.map((editedAttachment) =>
      SupplierOrderService.convertFromValidatedAttachmentToApiLinkToOrderRequestAttachment(
        editedAttachment
      )
    );

    linkAttachmentsMutation.mutate({
      s2SOrderId: orderId,
      attachments: editedAttachments,
      orderVersionId: orderVersionId,
    });
  };

  const areAttachmentsSubmitting = linkAttachmentsMutation.isPending;
  const isAttachmentsSubmittingExternallyDisabled = isSyncInProgress;
  const isAbandonEditConfirmationRequired =
    !isNavigationConfirmationDisabled &&
    (isOrderDirty || areAttachmentsDirty || arePriceModifiersDirty);

  const areGrandTotalAmountsEqual = SupplierOrderService.areAmountsEqual(
    originalOrderFormData.totalGrossAmount.amount,
    currentGrandTotal.amount
  );

  return (
    <>
      <NavigationPrompt
        isConfirmationRequired={isAbandonEditConfirmationRequired}
        renderNavigationPrompt={({ isActive, cancel, confirm }) => (
          <OrderEditDiscardChangesConfirmationModal
            isOpen={isActive}
            closeModal={cancel as () => void}
            onConfirm={confirm as () => void}
            onClose={cancel as () => void}
          />
        )}
      />

      <Modal isOpen={isAddItemModalOpen} closeModal={closeAddItemModal}>
        <AddItemModal
          orderId={orderId}
          onAddItem={(item: SupplierOrderItemForm) => {
            addItem(item);
          }}
          excludedItemIds={formOrderItems.map((item) => item.id)}
          closeModal={closeAddItemModal}
        />
      </Modal>

      <Modal isOpen={isSuccessModalOpen} closeModal={closeSuccessModal}>
        <ItemActionSuccessModal message={successModalMessage} closeModal={closeSuccessModal} />
      </Modal>

      <div className="flex flex-col min-h-full bg-neutral_100">
        <OrderConfiguratorHeader
          className="flex-none"
          headerLabel="Edit Order"
          submitButtonLabel="Continue"
          onSubmit={onOrderConfirm}
          cancelButtonLabel="Cancel"
          onCancel={onCancel}
          isSubmitButtonLoading={isSyncInProgress}
          hasOrderSummary={true}
          renderOrderSummary={() => (
            <OrderConfiguratorSummary
              itemsQuantity={numberOfItems}
              originalGrandTotal={originalOrderFormData.totalGrossAmount}
              newGrandTotal={currentGrandTotal}
              isGrandTotalVisible={true}
              isNewGrandTotalVisible={!areGrandTotalAmountsEqual}
              attachmentsQuantity={attachments.length}
            />
          )}
        />
        <FormProvider {...form}>
          <div className="flex-1">
            <div className={`py-5 ${containerPadding}`}>
              <ErrorMessage
                errors={errors}
                name="totalGrossAmount.amount"
                render={({ message }) => (
                  <Label size="200" color="text-dangerDefault" className="block mb-3">
                    {message}
                  </Label>
                )}
              />
              <OrderEditPriceModifiers
                className="mb-6.5"
                orderId={orderId}
                orderVersionId={orderVersionId}
                currencyCode={currencyCode}
                currentTotalAmount={currentGrandTotal.amount}
                showGenericSavingError={showGenericSavingError}
                formAmountAdditionalCosts={formAmountAdditionalCosts}
                formAmountDiscounts={formAmountDiscounts}
                appendFormAmountAdditionalCost={appendFormAmountAdditionalCost}
                removeFormAmountAdditionalCost={removeFormAmountAdditionalCost}
                appendFormAmountDiscount={appendFormAmountDiscount}
                removeFormAmountDiscount={removeFormAmountDiscount}
                onSetIsDirty={setArePriceModifiersDirty}
                onSetNewOrderVersion={setNewOrderVersion}
              />
              <div className="flex flex-col w-full">
                <div className="flex justify-between items-center mb-1 gap-3">
                  <Heading size="300">Ordered Items</Heading>
                  <AttachmentsModalController
                    attachments={attachments}
                    submitAttachmentsChanges={submitAttachmentsChanges}
                    onSetIsDirty={setAreAttachmentsDirty}
                    isSubmitting={areAttachmentsSubmitting}
                    isSubmittingExternallyDisabled={isAttachmentsSubmittingExternallyDisabled}
                    render={(openAttachmentsModal) => (
                      <RegularButton
                        className="ml-auto"
                        variant="secondary"
                        size="large"
                        label="Attachments"
                        onClick={openAttachmentsModal}
                        data-testid="openAttachmentsModalButton"
                      />
                    )}
                  />
                  <RegularButton
                    variant="secondary"
                    size="large"
                    label="Add item"
                    onClick={openAddItemModal}
                    LeadingIcon={PlusIcon}
                    data-testid="openAddItemModalButton"
                  />
                </div>
                <ErrorMessage
                  errors={errors}
                  name="items"
                  render={({ message }) => (
                    <Label size="200" color="text-dangerDefault" className="my-2">
                      {message}
                    </Label>
                  )}
                />
                <div data-testid="orderItemsContainer" className="min-w-full">
                  {formOrderItems.map((orderItem, index) => {
                    // If `replacementForItemId` is nil or there is no original item, then this item was
                    // not replaced.
                    // Only if search for replacement fails, we need to find original order item,
                    // e.g. to get original ordered quantity etc.
                    // If both searches fail, then this is a new item and there is no original item in the order.
                    const originalOrderFormItem =
                      originalOrderFormData.items.find(
                        (originalItem) => orderItem.replacementForItemId === originalItem.id
                      ) ||
                      originalOrderFormData.items.find(
                        (originalItem) => orderItem.id === originalItem.id
                      );

                    // Newly added items are not allowed to be replaced, and they do not have originally ordered data,
                    // so we can add checking for originalOrderItem existence to `isReplacement` condition.
                    const isReplacement =
                      SupplierOrderService.isOrderItemReplacement(orderItem) &&
                      !!originalOrderFormItem;

                    return isReplacement ? (
                      <Fragment key={orderItem.key}>
                        <OrderItemEditController
                          orderId={orderId}
                          orderVersionId={orderVersionId}
                          orderItem={orderItem}
                          orderItemIndex={index}
                          originalOrderItem={originalOrderFormItem}
                          replaceItem={replaceItem}
                          excludedItemIds={formOrderItems.map((item) => item.id)}
                        />
                        <OrderReplacedItemController orderItem={originalOrderFormItem} />
                      </Fragment>
                    ) : (
                      <OrderItemEditController
                        key={orderItem.key}
                        orderId={orderId}
                        orderVersionId={orderVersionId}
                        orderItem={orderItem}
                        orderItemIndex={index}
                        originalOrderItem={originalOrderFormItem}
                        replaceItem={replaceItem}
                        excludedItemIds={formOrderItems.map((item) => item.id)}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </FormProvider>
      </div>
    </>
  );
};
